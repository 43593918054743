export class StaticVariable {

    // static porturl = 'http://148.66.133.79:8081/DemoVticketApi/';
    // static printporturl = 'http://148.66.133.79:8085/SupportApi/';

    static porturl = 'http://148.66.133.79:8080/DemoVticketApi/';
    static printporturl = 'http://148.66.133.79:8080/SupportApi/';

    static loginTitle = 'TICKET BOOKING SOFTWARE';
    static loginPlace = 'PALANI - 624601';


    static templeNameEnglish = 'ARULMIGU DHANDAYUTHAPANI SWAMY TEMPLE';
    static placeNameEnglish = 'PALANI - 624601';


    static templeNameTamil = 'அருள்மிகு தண்டாயுதபாணி சுவாமி கோவில் ';
    static placeNameTamil = 'பழனி - 624601';

    static pageWidth = 297; // 297
    static pageHeight = 'auto'; //280
    static marginLeft = 35;//5
    static marginRight = 30;//5
    static marginTop = 95;
    static marginBottom = 0;
    static recColWidth1 = 35; //%
    static recColWidth2 = 9; //%
    static recColWidth3 = 56; //%
    static receiptHeader = false;
    static prebookingDuration = 2;
    static preBookingTime = '05:00';

    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';

    static mail = 'palanimurugan@gmail.com';
    static landLine = '0431 – 2670460, 2670557';
    static tollFree = '';

}

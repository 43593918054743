
import * as moment from 'moment';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { TransactionTicketBookingPojo } from 'src/app/pojo/transaction-ticket-booking.pojo';
import { ReceiptHeader } from '../extras/receipt-header';
import { qrPojo } from '../receipt/receipt-qr.pojo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class TicketBookingReceipt {


    // pdf method
    static ticketReceiptPdf(arg: TransactionTicketBookingPojo) {

        let body = [];
        let extraBody = [];
        let content = [];

        // qr pojo object
        let qrpojo = new qrPojo();

        qrpojo.txnid = arg.transaction.transactionId;
        qrpojo.txndate = arg.transaction.bookingDate;
        qrpojo.txnamount = arg.transaction.amount;
        qrpojo.bankrefno = arg.transaction.pgBankRefNum;

        content.push({ image: ReceiptHeader.receiptImg + '\n', width: 510 });

        body.push([{ text: arg.booking.ticketName + ' - ' + arg.booking.slotDet.slotName, bold: true, colSpan: 3, alignment: 'center', margin: [0, 5] }, {}, {}]);

        body.push([
            { text: 'E-RECEIPT', bold: true, colSpan: 2, alignment: 'center', margin: [0, 5] }, {},
            { qr: JSON.stringify(qrpojo), fit: 150, rowSpan: 11, alignment: 'center', margin: [0, 5], border: [true, true, true, false] }
        ]);

        body.push(['Transaction Id', { text: arg.transaction.transactionId, bold: true }, {}]);

        body.push(['Receipt Number', { text: arg.booking.bookingNo, bold: true }, {}]);

        body.push(['Receipt Date', { text: moment(arg.booking.registerDate).format('DD-MM-YYYY'), bold: true }, {}]);

        body.push(['Dharsan Date', { text: moment(arg.booking.bookedDate).format('DD-MM-YYYY'), bold: true }, {}]);

        let time = moment(arg.booking.slotDet.fromTime, 'HH:mm:ss').format('hh:mm A') + ' - ' + moment(arg.booking.slotDet.toTime, 'HH:mm:ss').format('hh:mm A');

        body.push(['Dharsan Time', { text: time, bold: true }, {}]);

        body.push(['No of Ticket', { text: arg.booking.noOfPerson, bold: true }, {}]);

        body.push(['Name', { text: arg.booking.name, bold: true }, {}]);

        body.push(['Place (City)', { text: arg.booking.city, bold: true }, {}]);

        body.push(['Id Proof', { text: arg.booking.proofType, bold: true }, {}]);

        body.push(['Id Number  ', { text: arg.booking.proofNumber, bold: true }, {}]);

        body.push(['Payment Id  ', { text: arg.transaction.pgPayUId, bold: true }, { text: moment(arg.booking.bookedDate).format('DD-MM-YYYY'), rowSpan: 2, alignment: 'center', fontSize: 16, bold: true, border: [true, false, true, false] }]);

        body.push(['Bank Reference Id  ', { text: arg.transaction.pgBankRefNum, bold: true }, {}]);

        body.push(['Transaction Status  ', { text: arg.transaction.status, bold: true }, { text: time, rowSpan: 2, alignment: 'center', fontSize: 16, bold: true, border: [true, false, true, true] }]);

        let amount = arg.transaction.amount;

        if (arg.transaction.amount == 0) {
            body.push(['Net Amount ', { text: '', bold: true }, {}]);
        } else if (arg.transaction.amount > 0) {
            body.push(['Net Amount ', { text: '₹ ' + arg.transaction.amount + ' (' + '' + ' ONLY)', bold: true }, {}]);
        }

        content.push({ 'table': { 'body': body, widths: ['30%', '40%', '30%'] }, margin: [0, 20] });

        extraBody.push(['Name', 'Proof Type', 'Proof No']);
        extraBody.push([{ text: arg.booking.name }, { text: arg.booking.proofType }, { text: arg.booking.proofNumber }]);

        for (let j = 0; j < arg.booking.extraPersons.length; j++) {
            extraBody.push([{ text: arg.booking.extraPersons[j].name }, { text: arg.booking.extraPersons[j].proofType }, { text: arg.booking.extraPersons[j].proofNo }]);
        }


        if (arg.booking.noOfPerson > 1) {
            content.push({ text: 'Proof Details', alignment: 'center', bold: true });
            content.push({ 'table': { 'body': extraBody, widths: ['40%', '30%', '30%'] }, margin: [0, 20] });
        }

        content.push({ text: 'Terms and conditions:', style: 'header', bold: true });

        let terms = [];

        for (let i = 0; i < arg.rtc.length; i++) {
            terms.push({ text: arg.rtc[i].detDescription ,font:'Sundaram'});
        }

        content.push({
            ul: terms
        });

        const PDF = { 'content': content, pageMargins: [20, 5, 20, 5] };

        // pdfMake.createPdf(dd).download("Receipt-" + this.transactionId);
        pdfMake.createPdf(PDF).open();

    }

}

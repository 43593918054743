import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReceiptComponent } from './receipt/receipt/receipt.component';

const routes: Routes = [
    { path: '', loadChildren: './Home-Page/home-page.module#HomePageModule' },
    // { path: 'online', loadChildren: './Online-Booking/online-booking.module#OnlineBookingModule' },
    { path: 'direct', loadChildren: './Direct-Booking/direct-booking.module#DirectBookingModule' },
    { path: 'receipt/:pathvariable', component: ReceiptComponent }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
    ],
    exports: [
        RouterModule
    ],
    providers: []
})

export class AppRoutingModule { }

import { TicketSlotDetailsPojo } from "src/app/pojo/ticket-master.pojo";
import { ParticipationPersonDetailPojo } from './participation-person-detail.pojo';

export class BookingPojo {

    userId: number;
    devoteeId: number;

    name: string;
    mobileNo: string;
    emailId: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    nriFlag: string;
    proofType: string;
    proofNumber: string;
    dob: Date;
    age: number;
    gender: string;
    imageId: number;
    fileName: string;
    fileAddressPath: string;
    status: string;

    bookedDate: Date;
    ticketId: number;
    ticketName: string;
    rate: number;
    noOfPerson: number;
    totalAmount: number;
    receiptType: string;
    slotId: number;

    bookingId: number;
    bookingNo: string;
    participatedDate: Date;
    participatedTime: string;
    registerDate: Date;
    registerTime: string;
    cancelledDate: Date;
    cancelledTime: string;
    bookedTime: string;
    code: number;
    res: string;
    reason: string;
    pasaliId: number;
    pasali: string;
    locationId: number;
    festivalFlag: string;
    slotFlag: string;
    bookingType: string;
    paymentType: string;
    paidStatus: string;
    transactionId: number;
    description: string;
    ticketType: string;
    designation: string;

    vAll: VerificationTransactionPojo[] = [];
    vlist: VerificationTransactionPojo[] = [];
    slotDet = new TicketSlotDetailsPojo();
    extraPersons: ParticipationPersonDetailPojo[] = [];
}

export class VerificationTransactionPojo {

    transId: number;
    bookingNo: string;
    status: string;
    verifiedDate: Date;
    verifiedTime: string;
    userId: number;
    locationId: number;
    checkPointId: number;
    checkPointName: string;

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";

import { SharedModule } from './Shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ReceiptModule } from './receipt/receipt.module';
import { DataTable } from 'angular-6-datatable-cc/src/DataTable';


@NgModule({
    declarations: [
        AppComponent,
       // DataTable
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ReceiptModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})

export class AppModule { }

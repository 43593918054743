import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { StaticVariable } from 'src/app/globle.class';
import { DummyHundiTransactionPojo } from '../receipt-pojo/DummyHundiTransactionPojo';
import { Observable } from 'rxjs/Observable';
import { DummyDonationTransactionPojo } from '../receipt-pojo/DummyDonationTransactionPojo';
import { DummyRoomBookingTransactionPojo } from '../receipt-pojo/DummyRoomBookingTransactionPojo';
import { LogDetailsPojo } from 'src/app/pojo/log-details-pojo';
import { TransactionPojo } from 'src/app/pojo/transaction.pojo';
import { DummyThirupaniDonationTransactionPojo } from '../receipt-pojo/DummyThirupaniDonationTransactionPojo';
import { TransactionTicketBookingPojo } from 'src/app/pojo/transaction-ticket-booking.pojo';



@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  constructor( private httpClient: HttpClient ) { }

  getRoomReceipt(id: string | number): Observable<DummyRoomBookingTransactionPojo> {
    const url = StaticVariable.porturl + 'RoomBookingReceiptByTransactionId/' + id;
    return this.httpClient.get<DummyRoomBookingTransactionPojo>(url);
}

gethundiReceipt(id: string | number): Observable<DummyHundiTransactionPojo> {
    const url = StaticVariable.porturl + 'HundiReceipt/' + id;
    return this.httpClient.get<DummyHundiTransactionPojo>(url);
}

getGeneralDonationReceipt(id: string | number): Observable<DummyDonationTransactionPojo> {
    const url = StaticVariable.porturl + 'GeneralDonationReceipt/' + id;
    return this.httpClient.get<DummyDonationTransactionPojo>(url);
}

getAnnathanamReceipt(id: string | number): Observable<DummyDonationTransactionPojo> {
    const url = StaticVariable.porturl + 'AnnathanamDonationReceipt/' + id;
    return this.httpClient.get<DummyDonationTransactionPojo>(url);
}

getTirupaniReceipt(id: string | number): Observable<DummyThirupaniDonationTransactionPojo> {
  const url = StaticVariable.porturl + 'TirupaniDonationReceipt/' + id;
  return this.httpClient.get<DummyThirupaniDonationTransactionPojo>(url);
}

addLogDetails(arg: LogDetailsPojo): Observable<any> {
  const url = StaticVariable.porturl + 'AddLogDetails';
  return this.httpClient.post(url, arg);
}

getUserTransaction(id: string | number): Observable<TransactionPojo> {
  const url = StaticVariable.porturl + 'GetUserTransactionByTransactionId/' + id;
  return this.httpClient.get<TransactionPojo>(url);
}


///
getReceiptByTransactionId(transactionId: number): Observable<TransactionTicketBookingPojo> {
  const url = StaticVariable.porturl + 'TicketBookingReceiptByTransactionId/' + transactionId;
  return this.httpClient.get<TransactionTicketBookingPojo>(url);
}

}

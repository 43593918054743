export class LogDetailsPojo {

    logId: number;
    userId: number;
    name: string;
    logDateTime: Date;
    logName: string;
    logDescription: string = "";
    ipAddress: string;

}
